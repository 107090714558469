.call-action-section {
    background: url('/Assets/Images/Pictures/call-action-sm.png');
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    height: 399px;
    @media (min-width: $screen-md) {
        height: 484px;
        background: url('/Assets/Images/Pictures/call-action-sd.png');
    }
    @media (min-width: $screen-lg) {
        background: url('/Assets/Images/Pictures/call-action.png');
    }
    &.no-fox {
        background: url('/Assets/Images/Pictures/call-action2-sd.png');
        height: 434px;
        @media (min-width: $screen-lg) {
            background: url('/Assets/Images/Pictures/call-action2.png');
        }
    }
    .call-action-content {   
        width: 80%;
        @media (min-width: $screen-sm) {
            width: 42%
        }
        @media (min-width: $screen-lg) {
            width: 34%
        }
    }
    .call-action-title {
        color: $color-call-action;        
        font-family: $font-SF-Semibold;
        // font-size: $font-28;
        // line-height: 34px;
        padding-bottom: 8px;
        &.alter {
            color: $color-white;
        }
        @media (min-width: $screen-md) {
            // font-size: $font-40;
            // line-height: 42px;
        }
        @media (min-width: $screen-lg) {
            // font-size: $font-50; 
            // line-height: 55px;
            padding-bottom: 15px;
        }
    }

    .call-action-about {
        color: $color-C2;
        // font-family: $font-SF-Text-Regular;
        // font-size: $font-16; 
        line-height: 28px;
        padding-bottom: 25px;
        &.alter {
            color: $color-E6;
        }
    }
    .btn {
        line-height: 40px;
        height: 40px;
        letter-spacing: 1.5px;
        width: 159px;
        @media (min-width: $screen-md) {
            line-height: 50px;
            height: 50px;
            width: 233px; 
        }
    }
    
}
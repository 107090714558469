//SF-Body-16
body, button, input, select, optgroup, textarea, p {
    color: $color-7E;
    font-family: $font-SF-Text-Regular;
    font-size: $font-16;
    // line-height: 28px;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}
.sf-body-14 {
    color: $color-7E;
    font-family: $font-SF-Text-Regular;
    font-size: $font-14;
    line-height: 24px;
}

.sf-cate-12 {
    color: $color-99;
    font-family: $font-SF-Display-Regular;
    font-size: $font-12;
    letter-spacing: 1.5px;
    line-height: 29px;
}

.sf-cate-14 {
    color: $color-99;
    font-family: $font-SF-Semibold;
    font-size: $font-14;
    letter-spacing: 1.2px;
    line-height: 29px;
}

.sf-cate-16 {
    color: $color-48;
    font-family: $font-SF-Semibold;
    font-size: $font-16;
    letter-spacing: 1.5px;
    line-height: 29px;
}

.sf-bigsize {
    color: $color-48;
    font-family: $font-SF-Ultralight;
    font-size: $font-45;
    line-height: 50px;
    @media (min-width: $screen-md) {
        font-size: $font-70;
        line-height: 77px;
    }
    @media (min-width: $screen-lg) {
        font-size: $font-80;
        line-height: 85px;
    }
}


h1 {
    color: $color-secondary;
    font-family: $font-SF-Semibold;
    font-size: $font-35;
    letter-spacing: 0.5px;
    line-height: 43px;
    @media (min-width: $screen-md) {
        font-size: $font-50;
        line-height: 55px;
    }
    @media (min-width: $screen-lg) {
        font-size: $font-70;
        line-height: 77px;
    }
}

h2 {
    color: $color-48;
    font-family: $font-SF-Semibold;
    font-size: $font-28;
    letter-spacing: 0;
    line-height: 34px;
    @media (min-width: $screen-md) {
        font-size: $font-40;
        line-height: 42px;
    }
    @media (min-width: $screen-lg) {
        font-size: $font-50;
        line-height: 55px;
    }
}

h3 {
    color: $color-48;
    font-family: $font-SF-Thin;
    font-size: $font-24;
    letter-spacing: 0.4px;
    line-height: 34px;
    @media (min-width: $screen-md) {
        font-size: $font-30;
        line-height: 43px;
    }
    @media (min-width: $screen-lg) {
        font-size: $font-35;
        line-height: 42px;
    }
}
h4 {
    color: $color-48;
    font-family: $font-SF-Light;
    font-size: $font-22;
    letter-spacing: 0.38px;
    line-height: 32px;
    @media (min-width: $screen-md) {
        font-size: $font-26;
        line-height: 36px;
    }
    @media (min-width: $screen-lg) {
        font-size: $font-30;
        line-height: 43px;
    }
}
h5 {
    color: $color-48;
    font-family: $font-SF-Semibold;
    font-size: $font-20;
    letter-spacing: 0.19px;
    line-height: 28px;
    @media (min-width: $screen-md) {
        font-size: $font-21;
        line-height: 30px;
    }
    @media (min-width: $screen-lg) {
        font-size: $font-24;
        line-height: 30px;
    }
}
h6 {
    color: $color-48;
    font-family: $font-SF-Medium;
    font-size: $font-18;
    letter-spacing: 0.3px;
    line-height: 27px;
    @media (min-width: $screen-lg) {
        font-size: $font-20;
        line-height: 27px;
    }
}

.savy-container {
    max-width: $max-width-xs;
    margin: auto;
    width: 100%;
    @media (min-width: $screen-sm) {
        max-width: $max-width-sm;
    }
    @media (min-width: $screen-md) {
        max-width: $max-width-md;
    }
    @media (min-width: $screen-lg) {
        max-width: $max-width-lg;
    }
}

.p-b-80 {
    padding-bottom: 80px;
    @media (min-width: $screen-md) {
        padding-bottom: 110px;
    }
    @media (min-width: $screen-lg) {
        padding-bottom: 150px;
    }
}

.p-t-150 {
    padding-top: 60px;
    padding-bottom: 30px;
    @media (min-width: $screen-sm) {
        padding-top: 80px;
        padding-bottom: 40px;
    }
    @media (min-width: $screen-md) {
        padding-top: 120px;
        padding-bottom: 50px;
    }
    @media (min-width: $screen-lg) {
        padding-top: 150px;
        padding-bottom: 60px;
    }
}


.chat-icon {
    position: fixed;
    top: 90vh;
    right: 1vw;
    z-index: 99999;
    cursor: pointer;
    img {
        width: 60px;
        height: 60px;
    }    
}
#chatBox {
    display: none;
}
.chat-welcome {
    width: 305px;
    height: 200px;
    background: transparent;
    position: fixed;
    bottom: 9vh;
    right: 2vw;
    z-index: 99999;
    .soc-chat {
        display: flex;
        height: 120px;
        align-items: flex-end;
        .soc-content {
            box-shadow: 0 2px 20px 0 rgba(0,0,0,0.15), 0 1px 6px 0 rgba(0,0,0,0.05);
            border-radius: 4px;
            padding: 15px 10px 15px 20px;
            margin: 0 0 5px 5px;
            background: $color-white;
            color: $color-99;
            span {
                color: $color-primary;
                padding-right: 5px;
            }
        }
    }
    .log-chat {
        display: flex;
        justify-content: space-around;
        height: 52px;
        width: 240px;
        float: right;
        background: #F1F1F1;
        box-shadow: 0 2px 32px 0 rgba(0,0,0,0.15), 0 1px 6px 0 rgba(0,0,0,0.05);
        border-radius: 4px;
        .sf-body-14 {
            color: $color-C2;
            line-height: 52px;
        }
        .icons-chat {
            line-height: 52px;
            img {
                margin-left: 5px;
            }
        }
    }
}

.chat-info {
    form {
        border-top: 2px solid #6B50D3;
        border-radius: 5px;
        background: #FFFFFF;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.12);
        position: absolute;
    }
    .form-item {
        padding-bottom: 5px;
        .label {
            font-size: $font-14;
            color: $color-black;
            line-height: 24px;
        }
        input {
            background: #F9F9F9;
            border: 1px solid #E7E7E7;
            box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.12);
            border-radius: 3px;
            height: 40px;
            width: 280px;
            padding: 0 20px;
            &::-webkit-input-placeholder {
                line-height: 24px;
                color: $color-C2;
            }
            &::-moz-placeholder {
                color: $color-C2;
            }
        }
    }
    display: none;
    width: 360px;
    height: 666px;
    position: fixed;
    bottom: 12vh;
    right: 2vw;
    z-index: 99999;
    box-shadow: 0 5px 40px 0 rgba(0,0,0,0.15);
    border-radius: 8px;
    overflow: hidden;
    @media (min-width: $screen-sm) {
        height: 726px;
    }
    .bg-top {
        background: url("/Assets/Images/Chat-icons/Large.png");
        background-size: cover;
        width: 100%;
        height: 310px;
        padding: 10px 40px;
        position: relative;
        @media (min-width: $screen-sm) {
            padding: 25px 40px;
        }
        h3 {
            color: $color-white;
            font-family: $font-SF-Text-Regular;
            padding: 20px 0 15px;
        }
        .sf-body-14 {
            color: #C1BFD2;
        }
    }
    .blank {
        height: calc(100% - 310px);
        width: 100%;
        background: $color-white;
    }
    
    .info-form {
        top: 36%;
        left: 50%;
        transform: translateX(-50%);
        width: 330px;
        height: 330px;
        padding: 15px 25px;
        .sf-body-14 {
            font-family: $font-SF-Semibold;
            color: $color-secondary;
            padding-bottom: 10px;
        }
        .chat-button {
            /* Rectangle: */
            background-image: linear-gradient(-134deg, #FCBC64 0%, #FF9800 35%, #F58220 100%);
            border-radius: 100px;
            font-family: $font-SF-Semibold;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 24px;
            height: 40px;
            width: 200px;
            display: flex;
            justify-content: space-evenly;
            border: 0;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
        }
    }
    .search-form {
        top: 84%;
        left: 50%;
        transform: translateX(-50%);
        width: 330px;
        height: 90px;
        padding: 10px 20px;
        input {
            width: 250px;
        }
        // .select2 {
        //     z-index: 999;
        // }
        
        .select2-container {
            // width: 250px;
            height: 40px;
            // z-index: 9999999999;
            .select2-selection__rendered {
                line-height: 40px;
            }
            .select2-selection--single {
                height: 39px;
            }
            .select2-selection__arrow b {
                display: none;
            }
        }
        a {
            display: inline;
            cursor: pointer;
            img {
                margin-top: -2px;
                vertical-align: middle;
                // display: inline;
            }
        }
    }
    
}

.chat-conversation {
    display: none;
    width: 360px;
    height: 575px;
    position: fixed;
    bottom: 12vh;
    right: 2vw;
    z-index: 99999;
    // background: #FFFFFF;
    box-shadow: 0 5px 40px 0 rgba(0,0,0,0.15);
    border-radius: 8px;
    overflow: hidden;
    &.small {
        .header {
            background: url("/Assets/Images/Chat-icons/Small.png");
            height: 75px;
            padding: 10px 70px;
            .sub-text, .subtext2 {
                display: none;
            }
            img {
                width: 42px;
                padding-top: 10px;
            }
        }
        .conversation {
            height: calc(100% - 75px);
        }
    }
    .header {
        background: url("/Assets/Images/Chat-icons/Medium.png");
        background-size: cover;
        width: 100%;
        height: 210px;
        padding: 30px 70px;
        color: $color-white;
        
        .back-icon {
            position: absolute;
            top: 5%;
            left: 7%;
        }
        .socnhi {
            display: flex;
            font-size: $font-14;
            // line-height: 24px;
        }
        .info {
            padding: 15px 10px;    
        }
        .bold {
            font-family: $font-SF-Semibold;
        }
        .grey {
            color: #C1BFD2;
        }
        .sub-text {
            padding: 17px 0 10px;
        }
    }
    .conversation {
        background: $color-white;
        display: flex;
        flex-direction: column;
        height: calc(100% - 210px);
        .messages {
            overflow: auto;
            flex-grow: 1;
            background: #F9F9F8;
            padding: 30px;
            .message {
                margin-bottom: 25px;
                &::after {
                    content: "";
                    clear: both;
                    display: block;
                }
                .time {
                    font-size: 14px;
                    line-height: 24px;
                    color: $color-C2;
                    text-align: center;
                    padding-bottom: 6px; 
                }
                .soc-icon {
                    display: inline-block;
                    vertical-align: bottom;
                    img {
                        width: 36px;
                    }
                }
                .content {
                    display: inline-block;
                    padding: 10px 20px;
                    background: $color-E6;
                    max-width: 240px;
                    margin-left: 15px;
                    border-radius: 8px;
                    word-wrap: break-word;
                    &.my {
                        float: right;
                        background: $color-primary;
                        color: $color-white;
                    } 
                }
            }
        }
        .chat-input {
            height: 60px;
            width: 100%;
            display: flex;
            flex-shrink: 0;
            background: $color-white;
            input {
                width: 80%;
                border: 0;
                padding-left: 20px;
                &::-webkit-input-placeholder {
                    line-height: 24px;
                    color: $color-C2;
                }
                &::-moz-placeholder {
                    color: $color-C2;
                }
            }
            .icons-chat {
                line-height: 60px;
                img {
                    margin-right: 15px;
                }
            }
        }
    }
}

/* MOBILE DISPLAY */
@media (max-width: 1024px) {
    nav {
        z-index: 9999;
        position: fixed;
        top: 0;
        width: 100%;
        background: $color-secondary;        
    }

    .nav-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        padding: 0 25px;
        // max-width: $max-width-xs;
        margin: auto;
        @media (min-width: $screen-sm) {
            width: 100%;
            // padding: 0 60px;
        }
        img {
            width: 125px;
            padding-top: 10px;
        }
        .nothing {
            width: 24px;
        }
    }

    .nav-mobile{
        height: 16px;
        width: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .c1,
    .c3 {
        width: 24px;
        height: 2px;
        background: $color-white;
        border-radius: 2px;
        transition: transform .3s ease-out, width .3s ease-in;
        transform: translateY(0) rotate(0);
    }
    .c2 {
        width: 15px;
        height: 2px;
        background: $color-white;
        border-radius: 2px;
        transition: transform .3s ease-out, width .3s ease-in;
        transform: translateY(0) rotate(0);
    }

    .close-c1 {
        transform: translateY(8px) rotate(45deg);
        transition: transform .3s ease-out;
    }

    .close-c2 {
        width: 0;
        transition: width .3s ease-in;
    }

    .close-c3 {
        transform: translateY(-6px) rotate(-45deg);
        transition: transform .3s ease-out;
    }

    .nav-bar {
        z-index: 2;
        padding: 0;
        width: 100%;
        text-align: left;
        display: none;
        box-shadow: 0 4px 2px -2px rgba(0, 0, 0, .1);
        position: relative;
        top: 0;
    }

    .open {
        background: $color-secondary;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        display: flex;
        display: -ms-flexbox;
        display: -webkit-flex;
        flex-direction: column;
        opacity: 0;
        transform: translateX(-50%);
        animation: 300ms show ease-out forwards;
        width: 250px;
        position: fixed;
        top: 60px;
        height: calc(100% - 60px);
        .nav-logo,
        .btn {
            display: none;
        }
        .nav-menu {
            height: 100%;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
        }
        a {
            display: block;
            color: $color-white;
            font-family: $font-SF-Display-Regular;
            padding: 15px 10px 20px 30px;
            font-size: $font-16;
            line-height: 28px;
            text-decoration: none;
            &:nth-child(2) {
                border: 0;
            }
            &:first-child {
                margin-top: 20px;
            }
            &.active span {
                border-right: 2px solid $color-primary;
                display: block;
            }
        }

        .call-icon {
            color: $color-primary;
            position: relative;
            margin-top: auto;
            img {
                width: 17px;
            }
            &::before {
                content: "";
                position: absolute;
                left: 20px;
                top: 0;
                width: 50px;
                border-top: 2px solid;
            }
        }
        .notque {
            padding-left: 5px;
        }
    }
   

}
/*L DESKTOP MODE */
@media (min-width: $screen-md) {
    nav {
        z-index: 9999;
        position: fixed;
        top: 0;
        background: $color-secondary;
        width: 100%;    
        .nav-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100px;
            max-width: $max-width-md;
            margin: auto;
            @media (min-width: $screen-lg) {
                max-width: $max-width-lg;
            }

            a.nav-logo {
                img {
                    width: 192px;
                    @media (min-width: $screen-lg) {
                        width: 240px;
                    }
                }
                padding-top: 15px;
            }

            .nav-menu {
                display: flex;
                align-items: center;
                a:not(:last-child) {
                    display: block;
                    border-top: 0;
                    color: $color-E6;
                    font-size: $font-16;
                    font-family: $font-SF-Text-Regular;
                    padding: 0 12.5px;
                    text-decoration: none;
                    position: relative;
                    @media (min-width: $screen-lg) {
                        padding: 0 25px;
                        font-size: $font-16;
                    }
                }
                a.linkk.active span {
                    padding-bottom: 5px;
                    border-bottom: 2px solid $color-primary;
                    transition: width .3s;
                }
                a.call-icon { 
                    img {
                        width: 17px;
                        vertical-align: bottom;
                        margin-right: 2px;
                    }
                    color: $color-primary;
                    font-family: $font-SF-Semibold;
                    font-size: $font-14;
                }
    
                a.search-icon {
                    img {
                        width: 17px;
                        vertical-align: bottom;
                    }
                    position: relative;
                    cursor: pointer;
                    span.que::after {
                        content:"";
                        position: absolute;
                        left: 10px;
                        right: 0;
                        height: 20px;
                        border-right: 1px solid;
                    }
                    span.notque {
                        display: none;
                    }
                }
                a.btn {
                    height: 40px;
                    line-height: 40px;
                    width: 180px;
                    @media (min-width: $screen-lg) {
                        font-size: $font-16;
                        height: 60px;
                        line-height: 60px;
                        width: 225px;
                    }
                }
            }  
            .open {
                flex-direction: row;
            }
        }
        
        .nav-container {
            display: none;
        }
    }
}
.overlay {
    height: 0;
    width: 100%;
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    background-color: $color-white; 
    overflow-y: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
    .overlay-content {
        position: relative;
        margin-top: 200px;
        @media (min-width: $screen-lg) {
            margin: 245px auto 0;            
        }
        h6 {
            font-family: $font-SF-Display-Regular;
            color: $color-99;
        }
        input {
            border: 0;
            color: $color-48;
            font-family: $font-SF-Display-Regular;
            font-size: $font-35;
            letter-spacing: 0.5px;
            line-height: 43px;
            @media (min-width: $screen-md) {
                font-size: $font-50;
                line-height: 55px;
            }
            @media (min-width: $screen-lg) {
                font-size: $font-70;
                line-height: 77px;
            }
        }
    }
    .closebtn {
        position: absolute;
        top: 8%;
        right: 3%;
        cursor: pointer;
        img {
            width: 40px;
        }
    }
}
/* ANIMATION */
@-webkit-keyframes show {
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes show {
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

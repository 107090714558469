.page-footer {
    bottom: 0;
    width: 100%;
}
.footer-section {
    background-color: $color-27;
    height: 538px;
    width: 100%;
    @media (min-width: $screen-sm) {
        height: 399px;
    }
    @media (min-width: $screen-md) {
        height: 310px;
    }   
    .footer-main {
        height: 456px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        // align-items: center;
        padding-top: 50px;
        @media (min-width: $screen-sm) {
            height: 339px;
        }
        @media (min-width: $screen-md) {
            height: 238px;
        }  
    }
    .footer-social {
        flex-basis: 100%;
        @media (min-width: $screen-sm) {
            flex-basis: 22%;
        }
        @media (min-width: $screen-md) {
            flex-basis: 17%;
        }
        @media (min-width: $screen-lg) {
            flex-basis: 12%;
        }  
    }

    .footer-right-side {
        display: flex;
        flex-basis: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 20px;
        @media (min-width: $screen-sm) {
            flex-basis: 70%;
            margin-bottom: 0;
        }
        @media (min-width: $screen-md) {
            flex-basis: 75%;
        }
        .footer-contact {
            flex-basis: 50%;
            @media (min-width: $screen-sm) {
                flex-basis: auto;
            }
        }
        .footer-app {
            position: relative;
            flex-basis: 100%;
            margin-top: 30px;
            @media (min-width: $screen-md) {
                flex-basis: 31%;
                margin-top: 0;
            }
            @media (min-width: $screen-lg) {
                flex-basis: auto;
            }
        }
    }
    .footer-logo {
        text-align: center; 
        img {
            width: 135px;
            @media (min-width: $screen-xs) {
                width: 180px;
            }
        }
    }
    .footer-social-icon {
        display: flex;
        justify-content: center;
        @media (min-width: $screen-sm) {
            justify-content: flex-start;
        }
        img {
            width: 30px;
            cursor: pointer;            
        }
        .fb-icon, .tw-icon, .ig-icon {
            flex-basis: 40px;
        }
    }

    .policy-items {
        display: flex;
        flex-direction: column;
        a {
            color: $color-7E;
            font-family: $font-SF-Text-Regular;
            font-size: $font-12;
            line-height: 18px;
            padding-bottom: 6px;
            text-decoration: none;
            @media (min-width: $screen-md) {
                font-size: $font-14;
                line-height: 24px;
                padding-bottom: 3px;
            }
        }
    }

    .contact-items {
        color: $color-7E;
        font-family: $font-SF-Text-Regular;        
        font-size: $font-12;
        line-height: 18px;
        max-width: 220px;
        div {
            padding-bottom: 6px;
            @media (min-width: $screen-md) {
                padding-bottom: 3px;
            }
        }
        @media (min-width: $screen-md) {
            font-size: $font-14;
            line-height: 24px;
            max-width: 280px;
        }
    }
    
    //Text&Styles 
    .policy-title, .contact-title, .app-title {
        font-size: $font-14;
        font-family: $font-SF-Semibold;
        color: $color-E6;
        letter-spacing: 1.5px;
        line-height: 29px;
        padding-bottom: 7px;
        text-transform: uppercase;
        @media (min-width: $screen-md) {
            padding-bottom: 14px;
            font-size: $font-16;
        }       
    }
    .app-title::before {
        content: "";
        position: absolute;
        height: 2px;
        width: 100%;
        top: -20px;
        left: 0px;
        background: #3b3b3b;
        display: block;
        @media (min-width: $screen-sm) {
            width: 100%;
            left: 0;
        }
        @media (min-width: $screen-md) {
            display: none;
        }
    }
    .app-items {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        @media (min-width: $screen-md) {
            justify-content: flex-end;
        }
        a {
            padding-right: 3px;
            @media (min-width: $screen-sm) {
                padding-right: 10px;
            }
            img {
                height: 40px;
                // @media (min-width: $screen-sm) {
                //     height: 25px;
                // }
                @media (min-width: $screen-md) {
                    height: 50px;
                }
                // @media (min-width: $screen-lg) {
                //     height: auto;
                // }
            }
        }
    }

    .line {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #595959;
    }
    .footer-end {
        height: 82px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: $screen-sm) {
            height: 57px;
        }
        @media (min-width: $screen-md) {
            height: 70px;
        }
        .footer-copyrights {
            color: $color-7E;
            font-family: $font-SF-Text-Regular;
            font-size: $font-12;
            line-height: 18px;
            letter-spacing: 1.5px;
            text-align: center;
            width: 70%;
            @media (min-width: $screen-sm) {
                font-size: $font-14;
                line-height: 24px;
            }
        }
    }
}
.title-section {
    position: relative;
    height: 384px;
    @media (min-width: $screen-sm) {
        height: 330px;
    }
    @media (min-width: $screen-md) {
        height: 384px;
    }
    @media (min-width: $screen-lg) {
        height: 537px;
    }
    .main-title {
        text-align: center;
        color: $color-white;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
    img {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
    }
}
@import "Partials/variables";
@import "Partials/style-guide.scss";
@import "Components/call-action";
@import "Components/footer";
@import "Components/navigation";
@import "Components/button";
@import "Components/title-section";
@import "Components/chat";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
}

html,
body {
    margin: 0 auto;
    min-height: 100%;
}
main {
    margin-top: 60px;
    @media (min-width: $screen-md) {
        margin-top: 100px;
    }
}
a {
    color: inherit;
    display: block;
    text-decoration: none;
}
.margin {
    margin: auto;
}

//Font-family
@font-face {
    font-family: $font-SF-Text-Regular;
    src: url("/Assets/Fonts/SF-Pro-Text-Regular.otf");
}

@font-face {
    font-family: $font-SF-Display-Regular;
    src: url("/Assets/Fonts/SF-Pro-Display-Regular.otf");
}

@font-face {
    font-family: $font-SF-Semibold;
    src: url("/Assets/Fonts/SF-Pro-Display-Semibold.otf");
}

@font-face {
    font-family: $font-SF-Ultralight;
    src: url("/Assets/Fonts/SF-Pro-Display-Ultralight.otf");
}

@font-face {
    font-family: $font-SF-Light;
    src: url("/Assets/Fonts/SF-Pro-Display-Light.otf");
}

@font-face {
    font-family: $font-SF-Thin;
    src: url("/Assets/Fonts/SF-Pro-Display-Thin.otf");
}

@font-face {
    font-family: $font-SF-Medium;
    src: url("/Assets/Fonts/SF-Pro-Display-Medium.otf");
}
.btn {
    border-radius: 100px;    
    color: $color-white;    
    font-family: $font-SF-Semibold;
    font-size: $font-14;
    height: 60px;
    line-height: 60px;
    letter-spacing: 1.2px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    width: 233px;
}
.btn-orange{
    background-image: linear-gradient(315deg, #FCBC64 0%, #FF9800 35%, #F58220 100%); 
}

.btn-violet{
    background-image: linear-gradient(315deg, #AB47BC 0%, #8E24AA 36%, #5D2E86 100%);
}

